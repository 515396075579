<template>
    <el-popover :width="360" trigger="click">
        <template #reference>
            <img src="@/assets/img/role/addIcon.png" class="w-22 h-22 cu-p mt-5" @click="addIcon" />
        </template>
        <template #default>
            <!-- {{ props.roleId }} -->
            <div class="pr-20 pl-20" v-loading="state.loding" :element-loading-text="config.loading.text"
                :element-loading-background="config.loading.background">
                <div class="input_box" style="margin-right: 0px;margin-bottom: 20px;">
                    <el-input v-model="state.chnname" :maxlength="config.inputMaxlength" show-word-limit
                        placeholder="输入成员姓名搜索" />
                </div>
                <div class="itembox">
                    <div class="item dp-f ai-c pt-r cu-p" v-for="(el, ind) in showData" :key="ind" @click="emit('choose',el)">
                        <img src="@/assets/img/role/peopleIcon.png" class="w-34 h-34 mr-20" />
                        <div class="mr-20">{{ el.chnname }}</div>
                        <div>{{ el.phone }}</div>
                        <img class="checkmark" src="@/assets/img/role/checkmark.png" v-if="el.role">
                    </div>
                </div>
            </div>
        </template>
</el-popover>
</template>
<script setup>

import { reactive, ref, unref, defineEmits,getCurrentInstance,computed } from "vue";
import { httpToken } from "@/utils/request";
import qs from "qs";
const props = defineProps({
    roleId: {
    type: String
  },//传入的印章数据
})
const instance = getCurrentInstance();
const emit = defineEmits(['choose'])
const state = reactive({
    chnname: '',
    roleData: [],
});
const showData=computed(()=>{
    return state.roleData.filter(function (item) {
        return item.chnname.includes(state.chnname)
    });
})
const addIcon = (() => {
    state.chnname=''
    selectAllUserByRoleId()
})
const selectAllUserByRoleId=(isup=false)=>{
    httpToken({
    method: "post",
    url: '/admin/sys-role/selectAllUserByRoleId',
    data: qs.stringify({
      roleId:props.roleId,
    }),
  }).then((res) => {
    state.roleData=res.data
    if(isup){
        instance.proxy.$forceUpdate();
    }
  })
}
defineExpose({
    selectAllUserByRoleId
});
</script>
<style lang="scss" scoped>
.itembox {
    max-height: 300px;
    overflow: auto;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    .item {
        padding: 0 10px;
        height: 46px;
        .checkmark{
            position: absolute;
            right: 16px;
        }
    }
    .item:hover{
        background-color: #f7f8f8;
    }
    &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 5px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
    }
    &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        //background-color: #8c8c8c;
        background-color: rgba(0, 0, 0, 0.25);
    }
    &::-webkit-scrollbar-track {
        background-color: #f6f6f6;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
        border: 0;
    }
}
::v-deep .el-popper{
    padding: 0!important;
}
::v-deep .el-input__suffix{
    display: none;
}
</style>